$(document).ready(function () {

	//Agents carousel - homepage
	if ($(".agent-list-container").length > 0) {
		$(".agent-list-container").carouFredSel({
			circular: true,
			infinite: false,
			width: 680,
			height: 420,
			align: "left",
			items: {
				width: 200,
				height: 420
			},
			scroll: {
				items: 1,
				duration: 500,
				pauseOnHover: true
			},
			prev: "#agents-list .prev-link",
			next: "#agents-list .next-link"
		});
	}

	//Featured Properties carousel - homepage
	if ($('.fplist').length) {
		if ($('.fplist div').hasClass('alt_property_container ')) {
			$('#featured-prop').addClass('minimalist');
		}
		//Move heading outside carousel
		var heading = $('.fplist').find('.fplist-title').text();
		$('.fplist').find('.fplist-title').remove();
		$('<div class="fplist-title">' + heading + '</div>').prependTo('#featured-prop');
		//Add links for next - prev arrows
		$('<a href="#" class="next-link"></a>').appendTo('#featured-prop');
		$('<a href="#" class="prev-link"></a>').appendTo('#featured-prop');
	}

	if ($("#featured-prop .fplist").length > 0) {
		$("#featured-prop .fplist").carouFredSel({
			circular: true,
			infinite: false,
			scroll: {
				items: 1,
				duration: 500,
				pauseOnHover: true
			},
			next: "#featured-prop .prev-link",
			prev: "#featured-prop .next-link"
		});
	}

	if ($(".featuredprop").length > 0) {
		$(this).hide();
	}

	$("li.bidx_image_list img").hover(function (e) {
		$(".bidx_image_main").attr('src', $(this).attr('src'));
	})

	//Tooltips
	$(".alt_virtualtour a").attr("title", " Virtual Tour");
	$(".alt_pricedrop").attr("title", "Reduced");
	$(".alt_green").attr("title", " Green");
	$(".alt_adult").attr("title", " Adult");
	$(".alt_shortsale a").attr("title", " Shortsale");
	$(".alt_foreclosure a").attr("title", "Foreclosure");
	$(".alt_view_similar a").attr("title", "More Like This");

	$(".alt_virtualtour a, .alt_pricedrop, .alt_green, .alt_adult, .alt_shortsale a, .alt_foreclosure a, .alt_view_similar a").tooltip({
		position: {
			my: "center bottom-20",
			at: "center left",
			using: function (position, feedback) {
				$(this).css(position);
				$("<div>")
					   .addClass("arrow")
					   .addClass(feedback.vertical)
					   .addClass(feedback.horizontal)
					   .appendTo(this);
			}
		}
	});



	// Code for print css
	// Clone detail page comments
	var detailComment = $(".detail_comment li").clone();
	var detailCommentprint = $(".detail_comment li").clone();
	var commentTitle = $(".detail-comment-title").clone();
	$(".detail_comment li").remove();
	$(detailComment).insertAfter($("#detailprop")).addClass("detailComment");
	$(detailCommentprint).insertAfter($("#detailprop")).addClass("detailCommentprint");
	$(commentTitle).insertAfter($("#detailprop")).addClass("detailtitle");

	$(".detailtitle").wrap($("<div class='printcomment'></div>"));
	$(".detailCommentprint").appendTo(".printcomment");

	var commentText = $(detailComment).text();
	if (commentText.length > 500) {
		commentText = commentText.slice(0, 500) + " ... ";
		$('.detailCommentprint').text(commentText);
	}

	// add notes heading on flyer
	$(".notes-print").prepend("<h2 class='notes'>Contact Info</h2>");
});
